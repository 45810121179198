export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/what-i-do',
    name: 'What-i-do',
    component: () => import(/* webpackChunkName: "whatido" */ '../views/What-i-do.vue')
  },
  {
    path: '/what-i-did',
    name: 'What-i-did',
    component: () => import(/* webpackChunkName: "Whatidid" */ '../views/What-i-did.vue')
  }
]
