<template>
  <div id="app" v-bind:class="{'shrinked': shrinkNav}">
    <div id="nav">
      <div class="top-nav">
        <div class="claim">
          <h2 class="txt-r">LORENZO<br><span class="txt-highlight">COGLIA</span>TI</h2>
          <h3 class="txt-r">WEB DEVELOPER</h3>
        </div>
        <div class="nav-links txt-r">
          <router-link to="/"><span>Who</span> I am</router-link> |
          <router-link to="/what-i-do"><span>What</span> I do</router-link> |
          <router-link to="/what-i-did"><span>What</span> I did</router-link>
        </div>
      </div>
      <!-- <div class="mobile-claim" @click="goHome()">
        <h2>LORENZO <span class="txt-highlight">COGLIA</span>TI</h2>
        <h3>WEB DEVELOPER</h3>
      </div> -->
      <transition name="fade">
        <div class="contact-me" v-if="!shrinkNav">
          <h3>Contact me</h3>
          <a class="nav-extra-link" href="mailto:lorenzo.cogliati@gmail.com">lorenzo.cogliati<span>@gmail.com</span></a>
          <a class="nav-extra-link" href="https://www.linkedin.com/in/lorenzo-cogliati-44972346/">Linked<span>In</span></a>
        </div>
      </transition>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
  name: 'App',
  data () {
    return {
      shrinkNav: false
    }
  },
  methods: {
    goHome () {
      this.$router.push('/')
    },
    scrollFunction () {
      if (document.body.scrollTop > 35 || document.documentElement.scrollTop > 35) {
        this.shrinkNav = true
      } else {
        this.shrinkNav = false
      }
    },
    isMobileDevice () {
      return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },
  created () {
    const self = this
    // const isMobile = this.isMobileDevice()
    window.onscroll = function () {
      if (screen.width < 1024) {
        self.scrollFunction()
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Josefin Sans', sans-serif;
  src: url('/assets/JosefinSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Space Grotesk', sans-serif;
  src: url('/SpaceGrotesk-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;}

html{
  width: 100%;
  height: 100%;
  font-size: 18px;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

h1,h2,h3,h4,h5,h6{
  // font-family: 'Bree Serif', serif;
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-size: 4rem;
}
h2{
  font-size: 3rem; 
}
h3{
  font-size: 2rem; 
}

* {
  -webkit-box-sizing: border-box; // Safari <= 5
     -moz-box-sizing: border-box; // Firefox <= 19
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p{
  line-height: 1.4rem;
}

ul{
  li{
    position: relative;
    line-height: 1.4rem;
    padding-left: 20px;
    &:before{
      position: absolute;
      left: 0px;
      top: 8px;
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: deeppink;
      border-radius: 50%;
    }
  }
}

#app {
  width: 100%;
  height: 100%;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  background: #8e9eab;
  background: -webkit-linear-gradient(to right, #8e9eab 0%, #ECE9E6 40%, #efeeec 100%);
  background: linear-gradient(to right, #8e9eab 0%, #ECE9E6 40%, #efeeec 100%);
  transition: background .5s ease-out;
  &.dark-mode{
    background: #0F2027;
    background: -webkit-linear-gradient(to left, #2C5364, #203A43, #0F2027);
    background: linear-gradient(to left, #2C5364, #203A43, #0F2027);
  }
}

#nav {
  float: left;
  width: 30%;
  height: 100%;
  padding-top: 5rem;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  background: linear-gradient(to left, #2C5364, #203A43, #0F2027);
  color: #efeeec;
  h2, h3{
    cursor: pointer;
  }
  .nav-links{
    margin-top: 5rem;
    a {
      font-weight: bold;
      color: #efeeec;
      &.router-link-exact-active {
        color: deeppink;
      }
      span{
        transition: color .4s ease-out;
      }
      &:hover{
        span{
          color: deeppink;
        }
      }
    }
  }
  .contact-me{
    margin-top: 5rem;
    width: 100%;
    text-align: right;
    .nav-extra-link{
      display: block;
      width: 100%;
      margin: 15px 0px;
      text-align: right;
      font-size: .9rem;
      color: deeppink;
      span{
        transition: color .4s ease-out;
      }
      &:hover{
        span{
          color: #efeeec;
        }
      }
    }
  }
}
.content{
  position: relative;
  float: left;
  width: 70%;
  height: 100%;
  padding: 4rem;
  background-color: #efeeec;
}


.txt-highlight{
  color: deeppink;
}

.txt-italic{
  font-style: italic;
}

.txt-l{
  text-align: left;
}

.txt-r{
  text-align: right;
}

.txt-small{
  font-size: .7rem;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  background-color: rgba(200,200,200,1);
  // border-radius: 3px;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb
{
  // border-radius: 3px;
  // #2C5364, #203A43, #0F2027
  background-color: #2C5364;
  // background-color: rgba(245,245,245,1);
  // border: 1px solid #FFFFFF;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner { background: transparent; }

@media (max-width: 1150px)  {
  #nav {
    padding-top: 3rem;
  }
  .content{
    padding: 3rem;
  }
}

@media (max-width: 1050px)  {
  #nav {
    padding-top: 2.5rem;
  }
  .content{
    padding: 2.5rem;
  }
}

@media (max-width: 1024px)  {
  #nav {
    padding-left: 10px;
    padding-right: 10px;
    a{
      font-size: .9rem;
    }
  }
}

@media (max-width: 1023px)  {
  #app {
    height: auto;
    &.shrinked{
      .content{
        margin-top: 75px;
      }
      #nav {
        height: 75px;
      }
    }
    .content{
      width: 100%;
      height: auto;
      margin-top: 165px;
      transition: all .4s ease-out;
    }
    #nav {
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 99;
      width: 100%;
      height: 165px;
      padding: 25px;
      text-align: left;
      background: linear-gradient(to left, #2C5364, #203A43, #0F2027);
      color: #efeeec;
      transition: all .4s ease-out;
      .top-nav{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: baseline;
        .claim{
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: baseline;
          h2, h3{
            font-size: 1.5rem;
            cursor: pointer;
            transition: all .4s ease-out;
            br{
              display: none;
            }
            &.txt-r{
              text-align: left;
            }
          }
          h3{
            margin-left: 10px;
            font-size: 1.1rem;
          }
        }
        .nav-links{
          display: inline-block;
          margin-top: 0px;
          float: right;
          line-height: 1.4rem;
          &.txt-r{
            text-align: left;
          }
          a {
            font-weight: bold;
            color: #efeeec;
            &.router-link-exact-active {
              color: deeppink;
            }
            span{
              transition: color .4s ease-out;
            }
            &:hover{
              span{
                color: deeppink;
              }
            }
          }
        }
      }
      .contact-me{
        margin-top: 15px;
        width: 100%;
        text-align: left;
        h3{
          font-size: 1.5rem;
          margin-left: 0px;
        }
        .nav-extra-link{
          display: block;
          width: 100%;
          margin: 10px 0px;
          margin-bottom: 0px;
          text-align: left;
          font-size: .9rem;
          color: deeppink;
          span{
            transition: color .4s ease-out;
          }
          &:hover{
            span{
              color: #efeeec;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 830px)  {
  #app {
    &.shrinked{
      .content{
        margin-top: 90px;
      }
      #nav {
        height: 90px;
      }
    }
    .content{
      margin-top: 190px;
    }
    #nav {
      height: 190px;
      .top-nav{
        .claim{
          flex-direction: column;
          h3{
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 770px)  {
  #app {
    #nav {
      h2{
        font-size: 1.3rem;
        line-height: 1.6rem;
      }
      h3{
        font-size: 1rem; 
      }
      .nav-links{
        // line-height: 1.2rem;
      }
    }
  }
}

@media (max-width: 640px)  {
  #app {
    &.shrinked{
      .content{
        margin-top: 90px;
      }
      #nav {
        height: 90px;
        .top-nav{
          .claim{
            h2{
              font-size: 1.1rem;
            }
            h3{
              font-size: .9rem;
            }
          }
        }
      }
    }
    .content{
      margin-top: 190px;
    }
    #nav {
      height: 190px;
      .top-nav{
        .claim{
          flex-direction: column;
          h3{
            margin-left: 0px;
          }
        }
        .nav-links{}
      }
    }
  }
}

@media (max-width: 640px)  {
  #app {
    #nav {
      .top-nav{
        .claim{
          h2{
            font-size: 1.1rem;
          }
          h3{
            font-size: .9rem;
          }
        }
      }
      .contact-me{
        h3{
          font-size: 1.1rem;
        }
        .nav-extra-link{
          a{
            font-size: .9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 530px)  {
  #app {
    &.shrinked{
      .content{
        margin-top: 85px;
      }
      #nav {
        height: 85px;
      }
    }
    .content{
      margin-top: 160px;
      padding: 2rem;
    }
    #nav {
      height: 160px;
      padding: 15px;
      .top-nav{
        flex-direction: column;
        align-items: center;
        .claim{
          flex-direction: row;
          h3{
            margin-left: 10px;
          }
        }
      }
      .contact-me{
        .nav-extra-link{
          margin-top: 5px;
        }
      }
    }
  }
}

@media (max-width: 390px)  {
  #app {
    &.shrinked{
      #nav {
        .top-nav{
          .claim{
            h2{
              font-size: .8rem;
            }
            h3{
              font-size: .8rem;
            }
          }
        }
      }
    }
    .content{
      padding: 1.5rem;
    }
    #nav {
      .top-nav{
        .claim{
          h2{
            font-size: .8rem;
          }
          h3{
            font-size: .8rem;
            margin-left: 5px;
          }
        }
        .nav-links{
          a{
            font-size: .8rem;
          }
        }
      }
      .contact-me{
        h3{
          font-size: .9rem;
        }
        .nav-extra-link{
          font-size: .8rem; 
        }
      }
    }
  }
}
/* always present */
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
  opacity: 1;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
